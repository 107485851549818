import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { BASE_URL_API } from "constant";

const MagicLink = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission success
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFunction = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); // Prevent the default form submission

      if (!executeRecaptcha) {
        setError("An unknown error has occurred.");
        return;
      }

      setIsSubmitting(true); // Indicate that the form is being submitted
      setError(""); // Clear previous errors

      try {
        const token = await executeRecaptcha("submit_form");
        if (!token) {
          setError("Please complete reCAPTCHA!");
          return;
        }

        const response = await fetch(`${BASE_URL_API}/api/magic-link`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, token }),
        });

        if (!response.ok) {
          throw new Error("Failed to send magic link. Please try again."); // Handle errors
        }

        setIsSubmitted(true); // Set submitted state to true on success
      } catch (error) {
        console.error(error);
        setError("Error sending magic link. Please try again."); // Set error message if something goes wrong
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    },
    [executeRecaptcha, email]
  );

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={`flex justify-center items-center`}
    >
      <div
        className={`flex flex-col justify-center rounded-3xl overflow-x-hidden overflow-y-hidden bg-white lg:w-[442px] sm:w-[320px] ssm:w-[320px] ssm:h-[300px] lg:h-[442px] ssm:m-[13px] lg:p-8 sm:p-2 ssm:py-3 ssm:px-6`}
      >
        {isSubmitted ? ( // Check if the form was submitted
          <p className="success-message">Please check your email!</p> // Show success message
        ) : (
          <form onSubmit={handleFunction}>
            <div style={{ marginBottom: "20px" }}>
              <p>Get a Magic Link to try our service.</p>
              <p>
                The link can only be used once. (make sure to check your spam
                inbox)
              </p>
            </div>
            {/* <label htmlFor="email"></label> */}
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update email state
              required // Make this field required
            />
            {error && <p className="error-message">{error}</p>}{" "}
            {/* Display error message */}
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default MagicLink;
