import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./index.css"; // Import CSS file for styling
import GenerateMagiLinkForm from "../../components/generate-magic-link-form";

const MagicLink = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcBxWkqAAAAAP_DBvP2arg-vsPMuykMo-j3kSK9">
      <GenerateMagiLinkForm />
    </GoogleReCaptchaProvider>
  );
};

export default MagicLink;
