import { Route, Routes } from "react-router-dom";
import {
  Home,
  Subscription,
  Conversion,
  GenerateMagicLink,
  ConsumeMagicLink,
  Voice,
} from "pages";

const RootRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="conversion" element={<Conversion />} />
        <Route path="signup-magiclink" element={<GenerateMagicLink />} />
        <Route
          path="login-magiclink/:magicLink"
          element={<ConsumeMagicLink />}
        />
        <Route path="voice" element={<Voice />} />
      </Routes>
    </>
  );
};

export default RootRoutes;
