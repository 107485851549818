import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import "./index.css"; // Import CSS file for styling
import { useNavigate } from "react-router";
import useLocalStorageState from "use-local-storage-state";
import { BASE_URL_API } from "constant";

const MagicLink = () => {
  const { magicLink } = useParams(); // Get magicLink from URL parameters
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState<any>(null); // State to hold user data
  const navigate = useNavigate();

  const [, setUserId] = useLocalStorageState("userId", {
    defaultValue: null,
  });
  const [, setUserDataLs] = useLocalStorageState("userData", {
    defaultValue: null,
  });

  // Effect to handle magic link verification
  useEffect(() => {
    const verifyMagicLink = async () => {
      if (!magicLink) {
        setError("Invalid magic link.");
        return;
      }

      setIsSubmitting(true); // Indicate that the request is being made
      setError(""); // Clear previous errors

      try {
        const response = await fetch(
          `${BASE_URL_API}/api/verify-magic-link/${magicLink}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Invalid or expired magic link.");
        }

        const data = await response.json(); // Get user data from the response
        setUserData(data); // Set user data
        setUserDataLs(data);
        setUserId(data.id);

        navigate("/conversion");
      } catch (error) {
        setError((error as any).message); // Set error message if something goes wrong
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    };

    verifyMagicLink(); // Call the function to verify magic link
  }, [magicLink, navigate, setUserDataLs, setUserId]); // Run effect when magicLink changes

  return (
    <div
      className={`flex flex-col justify-center rounded-3xl overflow-x-hidden overflow-y-hidden bg-white lg:w-[442px] sm:w-[270px] ssm:w-[270px] ssm:h-[210px] lg:h-[442px] ssm:m-[13px] lg:p-8 sm:p-2 ssm:py-3 ssm:px-6`}
    >
      {isSubmitting ? ( // Check if the request is being made
        <p className="loading-message">Verifying...</p> // Show loading message
      ) : error ? (
        <p className="error-message">{error}</p> // Show error message
      ) : userData ? (
        <p className="success-message">Welcome back, {userData.id}!</p> // Show success message
      ) : (
        <p className="info-message">
          Please wait while we verify your magic link...
        </p> // Info message while waiting
      )}
    </div>
  );
};

export default MagicLink;
