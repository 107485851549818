import { useEffect, useState } from "react";
import { Header } from "pages";
import { useNavigate } from "react-router";
import useLocalStorageState from "use-local-storage-state";
import { BASE_URL_API } from "constant";

const Home = () => {
  const [tos, setTos] = useState<boolean>(false);
  const [cookie, setCookie] = useState<boolean>(false);
  const [free, setFree] = useState<boolean>(false);
  const navigate = useNavigate();

  const [, , { removeItem: removeSubscriptionType }] = useLocalStorageState(
    "subscriptionType",
    {
      defaultValue: "",
    }
  );
  useEffect(() => {
    removeSubscriptionType();
  }, [removeSubscriptionType]);

  const handle = (e: any) => {
    const { value, checked } = e.target;
    if (value === "TOS" && checked) {
      setTos(true);
    }
    if (value === "Cookie" && checked) {
      setCookie(true);
    }
    if (value === "Free" && checked) {
      setFree(true);
    }
  };
  useEffect(() => {
    if (tos && cookie && free) {
      navigate("/subscription");
    }
  }, [tos, cookie, free, navigate]);

  return (
    <>
      <div
        className="flex lg:w-[100vw] h-[100vh] mx-auto justify-center"
        style={{ backgroundColor: "rgba(58, 180, 238, 1)" }}
      >
        <div className="flex flex-col relative overflow-x-hidden  overflow-y-hidden bg-[#3AB4EE] xl:w-[1512px] w-full h-full lg:h-[100vh] lg:pt-[40px] lg:pl-[59px] p-0">
          <Header />

          <div className="flex-1 overflow-y-hidden overflow-x-hidden">
            <div className="flex flex-col justify-center w-full h-full overflow-x-hidden overflow-y-auto">
              <div className="flex flex-col text-white justify-center  w-full font-bold   text-center lg:m-4 ssm:px-[16px]  ">
                <div className="lg:text-2xl ssm:text-md lg:m-2 ssm:m-2 ssm:mt-[260px]">
                  Life's tough talks are inevitable
                </div>
                <div className="lg:text-[34px] ssm:text-xl m-2">
                  With Hardtalks.ai, you can practice these conversations,
                </div>
                <div className="lg:text-[34px] ssm:text-xl m-2">
                  <span className="text-black">learn to express yourself</span>{" "}
                  better, and share your
                </div>
                <div className="lg:text-[34px] ssm:text-xl m-2">
                  <span className="text-black">feelings clearly </span>and{" "}
                  <span className="text-black">kindly</span>.
                </div>
                <div className="flex flex-row justify-center">
                  <div className="lg:text-[18px] ssm:text-[10px] m-2 text-center w-3/4 h-[20px]">
                    Get ready with{" "}
                    <span className="text-black">Hardtalks.ai</span>. and turn
                    every hard talk into a chance to grow and consent
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-center  pt-20">
                <div className="flex flex-col p-4  text-white bg-gray-600  mx-[16px] rounded-[22px]  h-full bg-opacity-70">
                  <div className="lg:text-2xl ssm:text-lg mb-2 text-center">
                    Tick all the white boxes below to get started
                  </div>
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-col justify-center mx-2 w-full lg:text-[16px] ssm:text-[14px] ssm:leading-5">
                      <div className="flex flex-row  ssm:h-[42px] ssm:mx-0 ssm:mb-[35px]  lg:mb-[20px]  items-center m-2">
                        <input
                          type="checkbox"
                          id="policy1"
                          value="TOS"
                          className="w-[30px] h-[30px]  text-transparent bg-transparent border-gray-300 rounded focus:ring-transparent"
                          onChange={(e) => handle(e)}
                        />
                        <label
                          htmlFor="policy1"
                          className="ml-[28px] lg:h-[24px] ssm:h-full font-normal"
                        >
                          By using our service you agree with our{" "}
                          <a
                            href={BASE_URL_API + "/api/pdf/tos.pdf"}
                            className="underline"
                          >
                            Terms of Service(TOS)
                          </a>
                        </label>
                      </div>
                      <div className="flex flex-row   ssm:mx-0   ssm:mb-[35px] lg:mb-[20px]  content-center justify-items-center items-center  ">
                        <input
                          type="checkbox"
                          id="policy2"
                          value="Cookie"
                          className="w-[30px] h-[30px]  text-transparent bg-transparent border-gray-300 rounded focus:ring-transparent"
                          onChange={(e) => handle(e)}
                        />
                        <label
                          htmlFor="policy2"
                          className="ml-[28px] lg:h-[24px] ssm:h-full font-normal"
                        >
                          By using our service you agree with our{" "}
                          <a
                            href={BASE_URL_API + "/api/pdf/cookies.pdf"}
                            className="underline"
                          >
                            Cookie policy
                          </a>
                        </label>
                      </div>
                      <div className="flex flex-row  ssm:mx-0   justify-start content-center justify-items-center items-center m-2">
                        <input
                          type="checkbox"
                          id="policy3"
                          value="Free"
                          className="w-[30px] h-[30px]  text-transparent bg-transparent border-gray-300 rounded focus:ring-transparent"
                          onChange={(e) => handle(e)}
                        />
                        <label
                          htmlFor="policy3"
                          className="ml-[28px] lg:h-[24px] ssm:h-full font-normal"
                        >
                          This Beta version is provided anonymously and for free
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
