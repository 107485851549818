import { useEffect } from "react";
import RootRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLocalStorageState from "use-local-storage-state";

function App() {
  const [userId] = useLocalStorageState("userId", {
    defaultValue: null,
  });
  const [visitorId] = useLocalStorageState("visitorId", {
    defaultValue: null,
  });
  const [userData] = useLocalStorageState("userData", {
    defaultValue: null,
  });

  useEffect(() => {
    if (userId != null && userData != null) {
      console.error(userData);
    }
    if (visitorId != null) {
      console.error(visitorId);
    }
  }, [userId, userData, visitorId]);

  return (
    <>
      <RootRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
