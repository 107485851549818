export const API_PREFIX_URL = "https://core.epventures.co/api/v1/case";
export const MAP_TOKEN =
  // "pk.eyJ1IjoiZGF0YWF3YXJlYWkiLCJhIjoiY2xydjFqbng4MGgydjJrbzY3cXJpbHBiZyJ9.gx0sydaFGQ6bY5GyDrJPVA";
  "pk.eyJ1IjoiZGF0YWF3YXJlYWkiLCJhIjoiY2xycDJ0cHM1MDFoMzJxcG96bWt2Z201aSJ9.xq34iSxQ6z38W88cQA3CZw";

export * from "./pdf";

// http://localhost:8081
export const BASE_URL_API =
  process.env.BASE_URL_API || "https://api.hardtalks.ai";
