import { useCallback, useEffect, useRef, useState } from "react";
import Card from "components/card";
import FormSpree from "components/formSpree";
import gsap from "gsap";
import { Header } from "pages";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import useLocalStorageState from "use-local-storage-state";

import "./index.css";
import { BASE_URL_API } from "constant";

const Subscription = () => {
  const [activePlan, setActivePlan] = useState(false);
  const [disabledFreePlan, setDisabledFreePlan] = useState(false);
  const elementRef = useRef(null);
  useEffect(() => {
    // GSAP animation from bottom to top
    gsap.fromTo(
      elementRef.current,
      { y: "100%" }, // Start from bottom (you might need to adjust this depending on your layout)
      { y: "0%", duration: 1, ease: "power3.out" } // Move to original position at top
    );
  }, []); // Empty dependency array means this effect runs once on mount
  const navigate = useNavigate();
  const [userId, setUserId] = useLocalStorageState("userId", {
    defaultValue: "",
  });
  const [, setUserData] = useLocalStorageState("userData", {
    defaultValue: "",
  });
  const [visitorId, setVisitorId] = useLocalStorageState("visitorId", {
    defaultValue: "",
  });
  const [, setSubscriptionType] = useLocalStorageState("subscriptionType", {
    defaultValue: "",
  });

  const handleFreePlan = () => {
    setSubscriptionType("free");

    if (disabledFreePlan) {
      return toast.error("Your free plan has expired.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
    navigate("/signup-magiclink");
  };
  const handlePaidPlan = useCallback(async () => {
    setSubscriptionType("pro");

    const existingUser = !!userId;
    const newVisitorId = uuidv4();
    if (!existingUser) setVisitorId(newVisitorId);

    const response = await fetch(`${BASE_URL_API}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        metadata: {
          existingUser,
          userId: existingUser ? userId : newVisitorId,
        },
      }),
    });
    const session = await response.json();

    if (session.url) {
      window.location.replace(session.url);
    }
  }, [userId, setSubscriptionType, setVisitorId]);

  const handleUnlimited = () => {
    setActivePlan(true);
  };

  const checkPaymentStatus = useCallback(async () => {
    try {
      const userIdToCheck = !!userId ? userId : visitorId;
      if (!userIdToCheck) return;

      const response = await fetch(`${BASE_URL_API}/hasPayed`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userIdToCheck,
        }),
      });
      const data = await response.json();
      console.log("data", data);

      if (!userId && data.user) {
        setUserData(data.user);
        setUserId(data.user.id);
        console.warn("found user from stripe");
      }

      if (!data.hasPayed) {
        setDisabledFreePlan(false);
      } else {
        setDisabledFreePlan(true);
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  }, [userId, visitorId, setUserData, setUserId]);

  useEffect(() => {
    checkPaymentStatus();
    const intervalId = setInterval(() => checkPaymentStatus(), 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkPaymentStatus]);

  return (
    <div className="flex justify-center">
      <div className="flex flex-col relative overflow-x-hidden  overflow-y-hidden bg-[#3AB4EE] xl:w-[1512px] w-full h-full lg:h-[100vh] lg:pt-[40px] lg:pl-[59px] p-0">
        <div className="lg:flex ">
          <Header />
        </div>
        <div className="flex-1  overflow-y-hidden overflow-x-hidden">
          <div
            ref={elementRef}
            //ANOTHER COMMENT FOR CHECKING
            //SEARCH COMMENT
            className="flex flex-col ssm:flex-col gap-4 lg:flex-row  sm:flex-row p-3 justify-center items-center w-full h-full overflow-y-auto overflow-x-hidden"
          >
            <Card
              index={0}
              buttonText="Try"
              title="Try for Free"
              item1="Limited to 10 mins"
              item2="We can use your conversation to improve our system"
              item3="One time only"
              handlePlan={handleFreePlan}
              backgroundColor={!disabledFreePlan ? "bg-white" : "bg-gray-500"}
            />

            <Card
              buttonText="Start"
              index={1}
              title="Pay as you go 4.99 EUR"
              item1="We don't train on your conversation"
              item2="Limited to 30 mins"
              handlePlan={handlePaidPlan}
              backgroundColor={"bg-white"}
            />

            {activePlan ? (
              <FormSpree />
            ) : (
              <Card
                buttonText="Inform Me"
                index={2}
                title="Monthly 69.99 EUR/month (Unlimited Access)"
                item1="Same as Pay as you go"
                item2="Unlimited access"
                handlePlan={handleUnlimited}
                backgroundColor={"bg-gray-500"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
