import React from "react";
import { useForm, ValidationError } from "@formspree/react";
// import { useNavigate } from "react-router-dom";
import "./index.css"; // Import CSS file for styling

const FormSpree = () => {
  const [state, handleSubmit] = useForm("xeqyrbbn");
  // const navigate = useNavigate();

  const handleFunction = async (event: any) => {
    await handleSubmit(event);
    // navigate('/conversion');
  };

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  return (
    // <div className="form-container"> {/* Wrapper div for styling */}
    <div
      className={`flex flex-col justify-center rounded-3xl overflow-x-hidden overflow-y-hidden 'bg-white' lg:w-[442px] sm:w-[270px] ssm:w-[270px] ssm:h-[210px] lg:h-[442px] ssm:m-[13px] lg:p-8 sm:p-2 ssm:py-3 ssm:px-6`}
    >
      <form onSubmit={handleFunction}>
        <label htmlFor="email">Email Address</label>
        <input id="email" type="email" name="email" />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default FormSpree;
